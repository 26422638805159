import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 607.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,607.000000) scale(0.100000,-0.100000)">
<path d="M2735 4493 c-37 -9 -85 -54 -110 -103 l-25 -49 0 -1241 0 -1241 21
-42 c24 -45 59 -81 99 -100 34 -16 2800 -23 2877 -7 39 9 62 21 92 52 51 51
61 86 61 224 l0 112 -27 5 c-16 3 -118 4 -228 1 -110 -3 -797 -7 -1528 -10
-1167 -4 -1328 -2 -1333 11 -4 8 25 61 68 127 41 62 103 158 138 213 36 55 67
102 71 105 3 3 8 10 10 15 4 10 50 80 224 345 183 279 240 365 245 375 3 6 39
62 81 125 42 63 84 130 95 148 10 17 22 32 26 32 4 0 8 4 8 10 0 16 212 332
252 376 81 88 187 134 309 134 180 0 291 -83 459 -344 39 -60 180 -272 188
-281 27 -33 362 -543 362 -552 0 -6 -5 -13 -12 -15 -27 -9 -663 4 -675 13 -6
6 -77 109 -157 229 -80 120 -150 221 -154 224 -5 3 -29 -26 -53 -64 -24 -38
-68 -107 -97 -152 -29 -46 -74 -116 -100 -157 -26 -41 -77 -119 -113 -175 -37
-56 -65 -103 -63 -105 2 -2 452 -3 1001 -2 l998 1 3 118 c1 64 1 126 -2 137
-3 12 -23 28 -48 38 -140 60 -246 143 -346 272 -74 95 -132 304 -132 474 0 85
21 221 43 272 7 19 17 50 21 70 9 48 112 203 172 259 27 25 70 59 96 75 26 17
49 36 52 43 4 9 -284 12 -1422 11 -785 -1 -1436 -4 -1447 -6z"/>
<path d="M5890 1791 l0 -131 58 0 c82 0 125 32 136 100 8 48 -9 101 -39 130
-20 19 -40 25 -90 28 l-65 4 0 -131z m124 57 c34 -48 10 -132 -39 -140 -18 -3
-20 3 -23 80 -3 81 -3 82 22 82 14 0 31 -9 40 -22z"/>
<path d="M6173 1868 c-23 -57 -73 -196 -73 -204 0 -2 13 -4 29 -4 22 0 30 6
35 25 6 22 12 25 51 25 39 0 47 -3 55 -25 7 -19 17 -25 39 -25 l29 0 -48 128
c-41 112 -50 127 -71 130 -22 3 -28 -4 -46 -50z m62 -80 c6 -25 5 -28 -19 -28
-30 0 -29 -2 -15 39 12 35 24 31 34 -11z"/>
<path d="M6330 1896 c0 -21 5 -25 38 -28 l37 -3 0 -103 0 -102 27 0 27 0 3
103 3 102 38 3 c32 3 37 7 37 28 0 24 0 24 -105 24 -105 0 -105 0 -105 -24z"/>
<path d="M6577 1797 c-26 -68 -47 -126 -47 -130 0 -4 13 -7 29 -7 22 0 30 6
35 25 6 22 12 25 51 25 39 0 47 -3 55 -25 7 -19 17 -25 39 -25 l30 0 -29 78
c-62 168 -66 177 -92 180 -23 3 -28 -5 -71 -121z m93 -19 c0 -11 -7 -18 -18
-18 -28 0 -31 8 -20 42 10 29 12 30 25 13 7 -11 13 -27 13 -37z"/>
<path d="M6832 1910 c-28 -12 -46 -51 -38 -84 5 -19 22 -32 66 -53 48 -22 60
-32 58 -48 -4 -29 -53 -33 -70 -6 -17 27 -58 28 -58 2 0 -56 127 -85 170 -39
41 44 17 96 -61 129 -34 15 -49 27 -49 40 0 24 55 27 68 3 9 -17 53 -16 58 1
2 6 -5 21 -15 34 -20 26 -90 38 -129 21z"/>
<path d="M7020 1815 c0 -99 2 -107 25 -130 43 -44 148 -28 163 24 4 14 7 67 7
116 l0 90 -27 3 -28 3 0 -100 c0 -91 -2 -101 -20 -111 -12 -6 -29 -8 -40 -5
-17 5 -20 18 -22 108 l-3 102 -27 3 -28 3 0 -106z"/>
<path d="M7260 1791 l0 -131 70 0 c63 0 74 3 97 26 21 21 24 30 18 57 -5 18
-7 55 -6 83 2 70 -18 87 -110 92 l-69 4 0 -131z m120 64 c17 -20 -3 -45 -37
-45 -25 0 -39 20 -29 45 3 8 16 15 29 15 14 0 30 -7 37 -15z m8 -112 c5 -36
-51 -53 -71 -22 -18 28 -1 51 35 47 25 -2 34 -8 36 -25z"/>
<path d="M7623 1908 c-24 -11 -53 -74 -53 -112 0 -46 13 -86 36 -111 19 -20
33 -25 73 -25 43 0 53 4 75 31 14 17 26 37 26 45 0 24 -46 16 -64 -11 -19 -30
-46 -32 -69 -7 -20 22 -23 122 -5 140 20 20 69 15 75 -8 4 -14 14 -20 35 -20
26 0 29 3 23 23 -17 56 -92 84 -152 55z"/>
<path d="M7864 1909 c-34 -17 -54 -62 -54 -121 0 -84 37 -128 108 -128 79 0
127 79 102 169 -16 60 -39 82 -90 87 -25 2 -54 -1 -66 -7z m90 -61 c23 -33 21
-103 -4 -128 -27 -27 -44 -25 -69 6 -40 51 -18 144 34 144 13 0 31 -10 39 -22z"/>
<path d="M8060 1791 l0 -131 31 0 31 0 -7 85 c-4 47 -4 85 -2 85 3 0 18 -38
33 -85 24 -72 31 -85 50 -85 18 0 25 12 44 78 32 111 43 114 35 10 l-7 -88 31
0 31 0 0 131 0 130 -36 -3 c-36 -3 -37 -4 -67 -86 -16 -45 -33 -80 -36 -77 -3
4 -18 41 -32 83 -25 76 -26 77 -62 80 l-37 3 0 -130z"/>
<path d="M7482 1688 c2 -15 11 -24 26 -26 18 -3 22 1 22 22 0 21 -5 26 -26 26
-21 0 -25 -4 -22 -22z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
